.Student_data{
    width: 95%;
    /* height: 50vh; */
    /* background-color: red; */
    /* border-bottom: .1px solid black; */
    padding-bottom: 20px;
}

.data_continer{
    width: 100%;
    margin: 10px 0;
    display: flex;
    

}

.data_name{
    width: 30%;
    background-color: rgb(55, 9, 99);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    color: rgb(255, 208, 0);
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-radius: 5px;
    font-weight: 600;


}

.data{
    width: 70%;
    font-weight: 600;

    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(39, 5, 71);
    border: .1px solid black;
    border-radius: 5px;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;



}


.data_title{
    width: 100%;
    
}

.data_title p{
    border: .1px solid gold;
    width: fit-content;
    margin: auto;
    padding: 5px 50px;
    border-radius: 5px;
    color: rgb(39, 5, 71);
    font-weight: 600;
}

.btn{
    color: white;
}

.finance_data{
    width: 100%;
   
    /* margin-top:50px ; */
}

.finance{
    width: 100%;
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: blueviolet; */
    border-bottom: .1px solid blueviolet;
}

.finance_discreption p{
padding: 0px !important;
margin: 0px !important;
}

.price{
    background-color: #faca0c;
    padding: 5px 0px;
    border-radius: 10px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.finance_discreption img{
    width: 40px;
    height: 40px;
    margin: 0px 10px;
}

.finance_discreption{
    display: flex;
}

.finance_data button{
    margin-bottom: 20px;
    cursor: pointer;

}

.data_continer input{
    width: 100%;

}

.select{
    width: 100% !important;
}

.AddData_continer{
    width: 100%;
    /* height: 50px; */
    margin: 15px 0;
    display: flex;
    /* background-color: blueviolet; */
    justify-content: space-between;
}

.AddData_continer span{
    width: 48%;
    /* background-color: red; */
}

.AddData_continer span p{
    padding: 0;
    margin: 0;
}

.AddData_continer span input{
    width: 100%;
    border: none;
    border-bottom: .1px solid #3c2365 !important;
}

.gender{
    width: 100% !important;
    border-bottom: .1px solid #3c2365;
}

.radios{
    display: flex;
    align-items: center;
    width: 50%;
    margin: 0px 10px;
}

.radios input{
    margin: 0px 10px;
}