.tablePageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px 0 0;
}

.actions-btns, .flex-column, .actions-btn {
  display: flex;
  gap: 10px;
  
}

.flex-column{
  flex-direction: column;
  padding: 10px;
}

.actions-btns > * {
  font-size: 23px;
}

.c-pointer {
  cursor: pointer;
}

.actions-btns {
  display: flex;
  gap: 8px;
  position: relative;
}

.tooltip {
  margin: 0 !important;
  translate: -50%;
  visibility: hidden;
  
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center !important;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.actions-btns > *:hover .tooltip {
  visibility: visible;
  opacity: 1;
}


.daily_reports{
  width: 90%;
  margin:15px auto;
  box-shadow: 0 2px 10px 0px black;
  cursor: pointer;
  border-radius: 15px;


}

.daily_reports h3{
  padding: 15px 0px;
  margin: auto;
  text-align: center;
}


.daily{
  display: block;
  width: 100% !important;
  height: 200px;
  background-image: url("../../assets/daily_report.jpg");
  background-size: cover;
  border-radius: 15px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;

}

.monthly{
  display: block;
  width: 100% !important;
  height: 200px;
  background-image: url("../../assets/monthly_report.jpg");
  background-size: cover;
  border-radius: 15px;
  background-position-y:-100px ;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.data_title{
  width: 100%;
  
}

.data_title h4{
  border: .1px solid gold;
  width: fit-content;
  margin: auto;
  padding: 5px 50px;
  border-radius: 5px;
  color: rgb(39, 5, 71);
  font-weight: 600;
}


.days{
  display: flex !important;
  flex-wrap: wrap;
  /* background-color: aquamarine; */
  width: 80%;
  margin:10px auto;

}

.days p{
  width: 50px;
  text-align: center;
  background-color: rgb(55, 9, 99);
  margin: 10px;
  padding: 10px 0;
  cursor: pointer;
  font-weight: 700;
  color: rgb(255, 208, 0);
  border-radius: 5px;
}


.month{
  width: 90%;
  background-color: rgb(55, 9, 99);
  margin:15px auto;
  display: block;
  text-align: center;
  font-weight: 700;
  padding: 15px 0;
  border-radius: 5px;
  cursor: pointer;
  color: rgb(255, 208, 0);

}
