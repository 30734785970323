form {
  
  padding: 20px;
  border-radius: 8px;
  
  
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  animation: fadeInUp 0.5s ease-out;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

label {
  font-size: 14px;
  color: #555;
  transition: color 0.3s ease, transform 0.3s ease;
}

input, textarea {
  padding: 12px 15px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

input:focus, textarea:focus {
  border-color: #1890ff;
  box-shadow: 0 0 8px rgba(24, 144, 255, 0.5);
  outline: none;
}

input:focus + label, textarea:focus + label {
  color: #1890ff;
  transform: translateY(-5px);
}

.animated-btn {
  padding: 10px 20px;
  border-radius: 4px;
  width: fit-content;
  
  border: none;
  background-color: #28a745;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.animated-btn:hover {
  background-color: #218838;
}

.animated-btn:active {
  transform: scale(0.95);
}

.cancel-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  margin-left: 8px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.cancel-btn {
  background-color: #f5f5f5;
  color: #333;
}

.rowEndDiv {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
}
