*{
  direction: ltr !important;
}

.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
  padding: 20px 0;
  overflow: auto;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  /* align-items: center; */
  z-index: 1000;
  opacity: 0;
  animation: fadeIn 0.3s forwards;
}


.custom-modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: min(800px, 100%);
  height: fit-content;
  max-width: 80%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transform: translateY(-20px);
  opacity: 0;
  animation: slideIn 0.3s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}


.custom-modal-exit {
  animation: slideOut 0.3s forwards;
}

@keyframes slideOut {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-20px);
    opacity: 0;
  }
}

.custom-modal-header {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 5px;
  border-bottom: 1px solid #8080804a;
  justify-content: space-between;
  margin-bottom: 15px;
}

.custom-modal-header span svg {
  font-size: 40px;
}

.custom-modal-header span {
  cursor: pointer;
}

.custom-modal-footer {
  width: 100%;
  margin-top: 18px;
  padding-top: 5px;
  border-top: 0.1px solid #80808054;
  display: flex;
  justify-content: flex-end;
  padding-top: 16px;
}

.custom-modal-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  margin-left: 8px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.cancel-btn {
  background-color: #f5f5f5;
  color: #333;
}

.cancel-btn:hover {
  background-color: #e0e0e0;
}

.ok-btn {
  background-color: #1890ff;
  color: white;
}

.ok-btn:hover {
  background-color: #40a9ff;
}

.custom-modal-btn:active {
  transform: scale(0.95);
}

.custom-modal-header h2 {
  font-size: 30px;
  font-weight: 600;
}


/* .custom-modal-body {
  width: 100%;
  display: flex;
  flex-direction: column;
} */