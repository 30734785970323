/* Container */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #A57EA1, #3c2365);
  overflow: hidden;
}

/* Form */
.login-form {
  background: #fff;
  border-radius: 12px;
  padding: 40px;
  max-width: 400px;
  width: 90%;
  box-shadow: 0 0 5px 5px #7c56b8d0;
  text-align: center;
  position: relative;
  overflow: hidden;
}

/* Logo */
.login-logo {
  margin-bottom: 20px;
  display: block;
  margin: 0 auto;
}

/* Title */
.login-title {
  font-size: 28px;
  color: #333;
  margin-bottom: 30px;
  font-weight: 600;
  letter-spacing: 1px;
}

/* Input Container */
.input-container {
  position: relative;
  margin-bottom: 20px;
}

/* Input */
.input-container input {
  width: 100%;
  padding: 15px 15px 15px 50px;
  border: 1px solid #ddd;
  border-radius: 25px;
  background-color: #f9f9f9;
  font-size: 16px;
  color: #333;
  outline: none;
  transition: all 0.3s ease;
}

/* Input Focus State */
.input-container input:focus {
  border-color: #623d9c;
  background-color: #fff;
}

/* Floating Label */
.input-container label {
  position: absolute;
  top: 50%;
  left: 15px;
  font-size: 16px;
  color: #aaa;
  pointer-events: none;
  transform: translateY(-50%);
  transition: all 0.3s ease, color 0.3s ease;
  background-color: #fff;
  padding: 0 4px;
  border-radius: 5px;
}

/* Label Focus State */
.input-container input:focus ~ label,
.input-container input:not(:placeholder-shown) ~ label {
  top: -12px;
  font-size: 12px;
  color: #3c2365;
  transform: translateY(0);
}

/* Label Animation */
@keyframes label-focus {
  0% {
    transform: translateY(50%) scale(1);
    opacity: 0.7;
  }
  50% {
    transform: translateY(-12px) scale(1.1);
    opacity: 1;
  }
  100% {
    transform: translateY(-12px) scale(1);
    opacity: 1;
  }
}

.input-container input:focus ~ label,
.input-container input:not(:placeholder-shown) ~ label {
  animation: label-focus 0.4s ease-in-out;
}

/* Input Icon */
.icon {
  position: absolute;
  left: 15px;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
  font-size: 20px;
  transition: color 0.3s ease;
}

.input-container input:focus ~ .icon {
  color: #3c2365;
}

/* Login Button */
.login-button {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 25px;
  background: #3c2365;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.5s ease, transform 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: .5s;

}

.login-button:hover {
  background-color: #623d9c;
  transform: scale(1.05);
}

/* Button Icon */
.button-icon {
  font-size: 18px;
}
